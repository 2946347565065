.text-justify  { text-align: justify !important; }
.text-nowrap   { white-space: nowrap !important; }
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    .text#{$infix}-left   { text-align: left !important; }
    .text#{$infix}-right  { text-align: right !important; }
    .text#{$infix}-center { text-align: center !important; }
  }
}

.text-lowercase  { text-transform: lowercase !important; }
.text-uppercase  { text-transform: uppercase !important; }
.text-capitalize { text-transform: capitalize !important; }
.font-weight-light  { font-weight: $font-weight-light !important; }
.font-weight-normal { font-weight: $font-weight-normal !important; }
.font-weight-bold   { font-weight: $font-weight-bold !important; }
.font-italic        { font-style: italic !important; }
.text-white { color: #fff !important; }